<script setup>
import { useLanguageStore } from '@/stores/language';
import { WdsItemPicker } from '@wds/components';

const props = defineProps({
  languageDataSet: {
    type: Object,
    required: false,
  },
});

const {
  locale,
  locales,
  setLocaleCookie,
  defaultLocaleObj,
} = useI18n();

const { isBelowMediumScreen } = useMq();

const languageStore = useLanguageStore();
const routeStore = useRouteStore();

setLocaleCookie(locale?.value);

const getLanguagesList = computed(() => {
  return locales?.value?.map((item) => {
    const hrefLangUrl = routeStore.getRouteFromLang(item?.code) || props?.languageDataSet?.[item?.code];
    const hrefUrl = hrefLangUrl ? `${hrefLangUrl}` : `/${item.url}/`;

    return {
      label: item.name,
      value: item.code,
      href: hrefUrl,
    };
  });
});

async function handleLanguageSelected(language) {
  const selectedLang = language || defaultLocaleObj.code;
  const langObj = locales?.value?.find((lang) => lang.code === selectedLang);

  languageStore.setLanguage(langObj);

  setLocaleCookie(selectedLang);
}
</script>

<template>
  <div class="language-picker">
    <WdsItemPicker
      :is-open="languageStore?.isLanguagePickerOpen"
      :heading="$t('t_LANGUAGE')"
      :items="getLanguagesList"
      :selected="languageStore?.getCurrentLanguage?.code"
      :is-modal="!toValue(isBelowMediumScreen)"
      :search-bar="false"
      @close-item-picker="languageStore.toggleLanguagePicker(false)"
      @item-selected="handleLanguageSelected"
    />
  </div>
</template>
