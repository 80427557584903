import groupsConfig from '@/config/groups';
import { PROPERTY_TYPE_DEFAULT, PROPERTY_TYPES } from '@/config/property-types.js';
import searchConfig from '@/config/search';

export function useSearch() {
  const runtimeConfig = useRuntimeConfig();
  const envData = runtimeConfig?.public;

  // ### STATIC VALUES
  let destinationInputTimer = null;
  const destinationInputDebounce = 750;
  const searchMaxResultsAllowed = 20;
  const recentSearchesStorageKey = 'recentSearches';

  // ### STORES
  const languageStore = useLanguageStore();
  const searchStore = useSearchStore();
  const routeStore = useRouteStore();
  const testingStore = useTestingStore();

  // ### DATA
  const searchResults = ref([]);
  const wizardActiveStep = ref('destination');
  const wizardIsOpen = ref(false);
  const recentSearchesEnabled = testingStore.hasEnabledFeature('web_recentSearches');
  const lastSearchedTerm = ref('');
  const userRecentSearches = useCookie(recentSearchesStorageKey, {
    default: () => [],
    domain: envData?.COOKIE_DOMAIN,
    path: '/',
    sameSite: 'Strict',
    secure: true,
    watch: true,
  });

  // ### COMPOSABLES
  const { t } = useI18n();
  const { $tracking } = useNuxtApp();
  const dateTime = useDateTime();
  const { isBelowMediumScreen } = useMq();
  const { searchForTerm } = useApiAutoComplete();
  const { getNearestCityByCoordinates } = useApiCities();
  const { getCountryById } = useApiCountries();
  const { getAreaById } = useApiAreas();
  const { getRegionById } = useApiRegions();
  const { useUrl } = useUrls();
  const { useFormatter } = useFormatters();

  // ### COMPUTED
  const getGuestsGroupTypes = computed(() => {
    return groupsConfig.groupTypes.map((gtype) => ({
      ...gtype,
      label: t(gtype.label),
      value: String(gtype.value),
    }));
  });

  const getSearchFormTranslations = computed(() => ({
    tAgeRanges: t('t_RWDAGERANGES'),
    tApplyDates: t('t_FORMINPUTAPPLY'),
    tCheckin: t('t_FORMINPUTCHECKIN'),
    tCheckout: t('t_FORMINPUTCHECKOUT'),
    tClearDates: t('t_FORMINPUTCLEAR'),
    tCurrentLocation: t('t_FORMINPUTCURRENTLOCATION'),
    tDates: t('t_FORMINPUTDATES'),
    tDatesNoCheckInError: t('t_FORMINPUTSELECTCHECKIN'),
    tDatesNoCheckOutError: t('t_FORMINPUTSELECTCHECKOUT'),
    tDestination: t('t_FORMINPUTDESTINATION'),
    tDestinationCurrentLocationError: t('t_FORMERRORNOCURRENTLOCATION'),
    tDestinationEmptyResultsError: t('t_FORMERROREMPTYRESULTS'),
    tDestinationFieldLabel: t('t_FORMINPUTWHEREDOYOUWANTTOGO'),
    tGroupType: t('t_RWDGROUPTYPE'),
    tGuests: t('t_FORMINPUTGUESTS'),
    tGuestsNoAgeRangeError: t('t_NOAGERANGEERROR'),
    tGuestsNoGroupTypeError: t('t_NOGROUPTYPEERROR'),
    tMsgNumNightsSelected: t('t_FORMINFOXNIGHTSSELECTED', { NUMNIGHTS: '##NUMNIGHTS##' }),
    tMsgSelectCheckInDate: t('t_FORMINPUTSELECTCHECKIN'),
    tMsgSelectCheckOutDate: t('t_FORMINPUTSELECTCHECKOUT'),
    tNextButton: t('t_FORMINPUTNEXT'),
    tSearchResultsAria: t('t_SEARCHRESULTSARIA'),
    tSubmitLetsGo: t('t_FORMINPUTLETSGO'),
  }));

  const getUserRecentSearchesForForm = computed(() => {
    if (!recentSearchesEnabled || !userRecentSearches.value?.length) {
      return null;
    }
    return [
      {
        label: t('t_SEARCHFORMRECENTSEARCHES'),
        items: userRecentSearches.value
          .sort((rsA, rsB) => new Date(rsB.createdOn) - new Date(rsA.createdOn))
          .map((rs) => {
            const guests = rs.guests;
            const guestsTranscode = guests.guestsQty > 1 ? 't_RWDGUESTS' : 't_RWDGUEST';

            const checkinObj = dateTime.formatToObject(new Date(rs.checkin));

            const checkoutObj = dateTime.addDaysToDate(checkinObj, rs.nights);

            const checkinLabel = dateTime.formatToHumanReadableShortDate(
              checkinObj,
              languageStore.getCurrentLanguage.iso,
            );
            const checkoutLabel = dateTime.formatToHumanReadableShortDate(
              checkoutObj,
              languageStore.getCurrentLanguage.iso,
            );

            rs.destination.subLabel = `${checkinLabel} - ${checkoutLabel} ∙ ${guests.guestsQty} ${t(guestsTranscode)}`;
            rs.destination.value = rs.destination.id;

            return { ...rs.destination };
          }),
      },
    ];
  });

  const getSearchFormCommonParams = computed(() => ({
    lang: languageStore.getCurrentLanguage.lang,
    destinationDefault: searchStore.getSearchLocation,
    destinationSearchResults: searchResults.value,
    destinationRecentSearches: getUserRecentSearchesForForm?.value,
    destinationMinCharsForSearch: 2,
    dateCheckIn: searchStore.getSearchCheckIn,
    dateCheckOut: searchStore.getSearchCheckOut,
    maxNightsOfStay: searchConfig.extendedMaxNights,
    guestsQty: searchStore.getSearchNumberOfGuests,
    guestsMax: searchConfig.maxGuests,
    groupThreshold: groupsConfig.minGuests - 1,
    groupTypes: getGuestsGroupTypes.value,
    selectedGroupType: searchStore.getSearchGroupType?.value,
    selectedGroupAges: searchStore.getSearchGroupAge,
    groupAgeRanges: groupsConfig.ageRanges,
    translations: getSearchFormTranslations.value,
  }));

  // ### METHODS
  const getSearchResultIcon = function (type) {
    switch (type) {
      case 'country':
        return 'globe';
      case 'city':
        return 'city';
      case 'citydistricts':
        return 'landmark';
      case 'property':
        return 'hostel';
      default:
        return 'location-pin';
    }
  };

  const handleSearchResults = function (searchResponse) {
    if (searchResponse?.length === 1 && searchResponse[0]?.type === 'no-results') {
      return [];
    }

    return searchResponse?.slice(0, searchMaxResultsAllowed)?.reduce((prevState, result, resultIndex) => {
      const labelContent = !result.label || result?.label.match(/, null/) ? result?.en?.label : result.label;

      if (!labelContent) {
        return prevState;
      }

      let categoryIndex = prevState.findIndex((c) => c.label === result.category);
      if (categoryIndex < 0) {
        prevState.push({
          label: result.category,
          items: [],
        });
        categoryIndex = prevState.length - 1;
      }

      const [label, ...labelSmall] = labelContent?.split(',');

      prevState[categoryIndex].items.push({
        id: result.id,
        type: result.type,
        city: result?.city,
        cityId: result?.cityId,
        label: label.trim(),
        labelSmall: labelSmall.join(',').trim(),
        labelSlug: useFormatter.normalizeUrl(label),
        value: String(result.id),
        icon: getSearchResultIcon(result.type),
        rank: resultIndex + 1,
      });

      return prevState;
    }, []);
  };

  const handleDestinationFieldClicked = function () {
    wizardActiveStep.value = 'destination';
    wizardIsOpen.value = toValue(isBelowMediumScreen?.value);

    if (!searchStore.getIsSearchActivated) {
      $tracking?.onSearchActivated('destination');
      searchStore.setSearchActivated(true);

      if (userRecentSearches.value?.length) {
        $tracking?.onRecentDestinationsShown();
      }
    }
  };

  const handleDestinationSearch = async function (search) {
    lastSearchedTerm.value = search.term;
    clearTimeout(destinationInputTimer);
    destinationInputTimer = setTimeout(async () => {
      const searchResponse = await searchForTerm(search.term);
      searchResults.value = handleSearchResults(searchResponse);
      $tracking?.onAutoCompleteSearchCall(search.term, searchResponse);
    }, destinationInputDebounce);
  };

  const handleDestinationItemSelected = function (item) {
    $tracking.onAutoCompleteSearchSelect(lastSearchedTerm.value, item);
    searchStore.setSearchParam('location', { ...item });
    searchStore.setSearchParam('phrase', `${item?.label}${item.labelSmall ? `, ${item.labelSmall}` : ''}`);
  };

  const buildSearchRouteAndTrackingData = async function (params) {
    let searchUrl = null;
    const trackingObject = {};

    const propertyTypeObj
      = PROPERTY_TYPES.find((pt) => t(pt.slug) === routeStore.getPropertyType) || PROPERTY_TYPE_DEFAULT;

    const extraParams = {
      from: dateTime.formatToFlat(params.checkin),
      to: dateTime.formatToFlat(params.checkout),
      guests: params.guests.guestsQty,
    };

    switch (params.destination.type) {
      case 'continent': {
        const continentData = {
          propertyTypeSlug: t(propertyTypeObj.slug),
          urlFriendlyContinent: params.destination?.labelSlug || useFormatter.normalizeUrl(params.destination?.label),
        };
        trackingObject.destination_continent = continentData?.urlFriendlyContinent;

        searchUrl = useUrl.getContinentPageUrl(continentData, {});
        break;
      }
      case 'country': {
        const countryInfo = await getCountryById(propertyTypeObj.key, params.destination?.id);
        const countryData = {
          propertyTypeSlug: t(propertyTypeObj.slug),
          urlFriendlyContinent: countryInfo.urlFriendlyContinent,
          urlFriendlyCountry: countryInfo.urlFriendlyName,
        };
        trackingObject.destination_country = countryData?.urlFriendlyCountry;
        trackingObject.destination_continent = countryData?.urlFriendlyContinent;
        searchUrl = useUrl.getCountryPageUrl(countryData, {});
        break;
      }
      case 'state': {
        const areaInfo = await getAreaById(propertyTypeObj.key, params.destination?.id);
        const areaData = {
          propertyTypeSlug: t(propertyTypeObj.slug),
          urlFriendlyContinent: areaInfo.urlFriendlyContinent,
          urlFriendlyCountry: areaInfo.urlFriendlyCountry,
          urlFriendlyArea: areaInfo.urlFriendlyName,
        };
        trackingObject.destination_area = areaData?.urlFriendlyArea;
        trackingObject.destination_country = areaData?.urlFriendlyCountry;
        trackingObject.destination_continent = areaData?.urlFriendlyContinent;
        searchUrl = useUrl.getAreaPageUrl(areaData, {});
        break;
      }
      case 'region': {
        const regionInfo = await getRegionById(propertyTypeObj.key, params.destination?.id);
        const regionData = {
          propertyTypeSlug: t(propertyTypeObj.slug),
          urlFriendlyContinent: regionInfo?.urlFriendlyContinent,
          urlFriendlyCountry: regionInfo?.urlFriendlyCountry,
          urlFriendlyRegion: regionInfo?.urlFriendlyName,
        };
        trackingObject.destination_region = regionData?.urlFriendlyRegion;
        trackingObject.destination_country = regionData?.urlFriendlyCountry;
        trackingObject.destination_continent = regionData?.urlFriendlyContinent;
        searchUrl = useUrl.getRegionPageUrl(regionData, {});
        break;
      }
      case 'city': {
        const searchString = [params.destination.label];

        if (params.destination.labelSmall) {
          searchString.push(params.destination.labelSmall);
        }

        const cityLocationData = {
          searchString: searchString.join(', '),
          cityId: params.destination.id,
          cityName: params.destination.label,
          countryName: params.destination.labelSmall,
          dateCheckIn: extraParams.from,
          dateCheckOut: extraParams.to,
          numOfGuests: extraParams.guests,
        };

        trackingObject.destination_city = params.destination?.label;
        trackingObject.destination_city_id = params.destination?.id?.toString();
        trackingObject.destination_country = params.destination?.labelSmall;
        trackingObject.auto_sort_order_applied = 'sfab';
        trackingObject.page_sort_order_applied = 'sfab';
        trackingObject.listing_type = 'list';

        searchUrl = useUrl.getCityPageUrlDynamic(cityLocationData);
        break;
      }
      case 'citydistricts': {
        const cityDistrictParts = params.destination?.labelSmall.split(',');

        const cityDistrictData = {
          searchString: params.destination.labelSmall,
          cityId: params.destination?.cityId,
          cityName: cityDistrictParts[0].trim(),
          countryName: cityDistrictParts[1].trim(),
          dateCheckIn: extraParams.from,
          dateCheckOut: extraParams.to,
          numOfGuests: extraParams.guests,
        };

        trackingObject.destination_district = params.destination.label;
        trackingObject.destination_city = cityDistrictParts[0];
        trackingObject.destination_city_id = params.destination.cityId?.toString();
        trackingObject.destination_country = cityDistrictParts[1];
        trackingObject.auto_sort_order_applied = 'sfab';
        trackingObject.page_sort_order_applied = 'sfab';
        trackingObject.listing_type = 'list';

        searchUrl = useUrl.getCityPageUrlDynamic(cityDistrictData, { district: params.destination?.id });
        break;
      }
      case 'property': {
        const propertyLocationData = {
          propertyId: params.destination?.id,
          urlFriendlyCity: useFormatter.normalizeUrl(params.destination?.city),
          urlFriendlyProperty: useFormatter.normalizeUrl(params.destination?.label),
        };
        const propertyLocationQuery = {
          from: extraParams.from,
          to: extraParams.to,
          guests: extraParams.guests,
        };

        trackingObject.property_name = propertyLocationData?.urlFriendlyProperty;
        trackingObject.destination_city = propertyLocationData?.urlFriendlyCity;
        trackingObject.product_id = params.destination?.id?.toString();
        trackingObject.sku = params.destination?.id?.toString();

        searchUrl = useUrl.getPropertyPageUrlDynamic(propertyLocationData, propertyLocationQuery);
        break;
      }
      default:
        return;
    }

    if (
      params.guests.guestsQty >= groupsConfig.minGuests - 1
      && ['city', 'citydistricts', 'property'].includes(params.destination.type)
    ) {
      const groupType = params.guests.groupType;
      const groupAges = params.guests.groupAges;

      if (groupType) {
        searchUrl += `&groupType=${groupType}`;
        trackingObject.group_type = groupsConfig.groupTypes.find((gt) => gt.value === Number.parseInt(groupType)).code;
      }

      if (groupAges.length > 0) {
        groupAges.forEach((groupAge) => {
          searchUrl += `&groupAgeRange=${groupAge}`;
        });
        trackingObject.group_age_range = groupAges
          .map((groupAge) => {
            const ageRangeObj = groupsConfig.ageRanges.find((ar) => ar.value === groupAge);
            return ageRangeObj?.label || null;
          })
          .join(',');
      }
    }

    if (routeStore.isPropertyPage) {
      searchUrl += '&origin=microsite';
    }

    const dateLeadTime = dateTime.getLeadTime(extraParams.from);

    trackingObject.arrival_date = extraParams.from;
    trackingObject.departure_date = extraParams.to;
    trackingObject.number_nights = dateTime.getDiffInDays(extraParams.from, extraParams.to);
    trackingObject.number_guests = extraParams.guests;
    trackingObject.property_type = routeStore.getPropertyType;
    trackingObject.lead_time = dateLeadTime > 0 ? dateLeadTime : 'zero';
    trackingObject.category = propertyTypeObj.key;
    trackingObject.search_keywords = params.destination?.labelSlug;

    return {
      searchUrl,
      trackingObject,
    };
  };

  const handleRecentDestinationItemSelected = async function (destination) {
    $tracking?.onRecentDestinationClicked();

    const existingDestination = toRaw(userRecentSearches.value).find((search) => {
      return JSON.stringify(search.destination) === JSON.stringify(destination);
    });

    const checkinObj = dateTime.formatToObject(new Date(existingDestination.checkin));
    const checkoutObj = dateTime.addDaysToDate(checkinObj, existingDestination.nights);

    const paramsToBuildRoute = {
      destination: existingDestination.destination,
      checkin: checkinObj,
      checkout: checkoutObj,
      guests: existingDestination.guests,
    };

    const searchRoute = await buildSearchRouteAndTrackingData(paramsToBuildRoute);

    setTimeout(() => {
      navigateTo(searchRoute.searchUrl, { external: true });
    }, 100);
  };

  const handleCurrentLocation = async function (position) {
    wizardActiveStep.value = 'destination';
    if (position.error) {
      return;
    }

    const latitude = position?.latitude || 0;
    const longitude = position?.longitude || 0;

    const nearestCity = await getNearestCityByCoordinates(latitude, longitude);

    if (nearestCity?.id) {
      handleDestinationItemSelected({
        id: nearestCity?.id,
        type: 'city',
        label: nearestCity?.name,
        labelSmall: nearestCity?.country,
        value: String(nearestCity?.id),
      });
    }
    wizardActiveStep.value = 'dates';
  };

  const handleDatesFieldClicked = function () {
    wizardActiveStep.value = 'dates';
    wizardIsOpen.value = toValue(isBelowMediumScreen?.value);

    if (!searchStore.getIsSearchActivated) {
      $tracking?.onSearchActivated('check-in date');
      searchStore.setSearchActivated(true);
    }
  };

  const handleDatesSelected = function (params) {
    searchStore.setSearchCheckIn(params?.checkin);
    searchStore.setSearchCheckOut(params?.checkout);
  };

  const handleGuestsFieldClicked = function () {
    wizardActiveStep.value = 'guests';
    wizardIsOpen.value = toValue(isBelowMediumScreen?.value);

    if (!searchStore.getIsSearchActivated) {
      $tracking?.onSearchActivated('guests');
      searchStore.setSearchActivated(true);
    }
  };

  const handleGuestsNumberChanged = function (numGuests) {
    searchStore.setSearchParam('guests', numGuests);
  };

  const handleGuestsGroupTypeChanged = function (groupType) {
    const groupTypeObject = getGuestsGroupTypes.value.find((gtype) => gtype.value === groupType);
    searchStore.setSearchParam('groupType', groupTypeObject);
  };

  const handleGuestsGroupAgesChanged = function (groupAges) {
    searchStore.setSearchParam('groupAge', groupAges);
  };

  const handleWizardClose = function () {
    wizardIsOpen.value = false;
  };

  const handleWizardOpen = function () {
    wizardActiveStep.value = 'destination';
    wizardIsOpen.value = true;

    if (!searchStore.getIsSearchActivated) {
      $tracking?.onSearchActivated(wizardActiveStep.value);
      searchStore.setSearchActivated(true);
    }
  };

  const handleSaveSearchInStorage = function (params) {
    const checkinLabel = dateTime.formatToFlat(params.checkin);

    const newUserRecentSearchEntry = {
      checkin: checkinLabel,
      nights: dateTime.getDiffInDays(params.checkin, params.checkout),
      guests: params.guests,
      destination: {
        id: params.destination.id,
        label: params.destination.label,
        labelSmall: params.destination.labelSmall,
        icon: params.destination.icon ?? 'location-pin',
        type: params.destination.type,
      },
      createdOn: new Date(),
    };

    if (params.destination.type === 'citydistricts') {
      newUserRecentSearchEntry.destination.cityId = params.destination.cityId;
    }

    /* REMOVE EXPIRED SEARCHES */
    const upToDateRecentSearches = userRecentSearches.value.filter((us) => {
      const dayDiffFromToday = dateTime.getDiffInDays(dateTime.today, us.checkin);
      const dayDiffFromExpiry = dateTime.getDiffInDays(us.createdOn, dateTime.today);
      return dayDiffFromToday >= 0 && dayDiffFromExpiry > searchConfig.recentSearchesExpirationDays;
    });
    if (upToDateRecentSearches?.length) {
      userRecentSearches.value = upToDateRecentSearches;
    }

    const destinationRecentSearchIndex = userRecentSearches.value.findIndex(
      (obj) =>
        obj.destination.label === params.destination.label
        && obj.destination.labelSmall === params.destination.labelSmall,
    );

    if (destinationRecentSearchIndex < 0) {
      // REMOVE OLDEST RECENT SEARCH
      if (userRecentSearches.value.length >= searchConfig.maxRecentSearchesShown) {
        userRecentSearches.value.pop();
      }
      userRecentSearches.value.unshift(newUserRecentSearchEntry);
    } else {
      userRecentSearches.value.splice(destinationRecentSearchIndex, 1);
      userRecentSearches.value.unshift(newUserRecentSearchEntry);
    }
  };

  const handleSubmit = async function (params) {
    searchStore.setSearchCheckIn(params.checkin);
    searchStore.setSearchCheckOut(params.checkout);

    const searchUrlAndTrackingData = await buildSearchRouteAndTrackingData(params);

    const redirectUrl = searchUrlAndTrackingData.searchUrl;
    const trackingData = searchUrlAndTrackingData.trackingObject;

    if (redirectUrl) {
      // ### SAVE SEARCH TO STORAGE
      if (testingStore.hasEnabledFeature('web_recentSearches')) {
        handleSaveSearchInStorage(params, redirectUrl);
      }

      $tracking?.onSearchSubmitted(trackingData);

      setTimeout(() => {
        navigateTo(redirectUrl, { external: true });
      }, 100);
    }
  };

  // @TODO - STATICWEB-311 - Remove all when experiment is complete
  const handleInventoryToggleClicked = function (toggle) {
    const isInventoryToggleDefaultOn = computed(
      () => testingStore.getFeatureVariables('web_3PITogglePaintedDoorTest')?.variation_type === 'on_default',
    );
    const variationText = isInventoryToggleDefaultOn ? 'Variation B' : 'variation A';
    $tracking.onThirdPartyInventoryToggleChange(toggle?.selected, variationText);
  };

  return {
    wizardIsOpen,
    wizardActiveStep,
    getGuestsGroupTypes,
    getSearchFormTranslations,
    getSearchFormCommonParams,
    handleSearchResults,
    handleDestinationFieldClicked,
    handleDestinationSearch,
    handleDestinationItemSelected,
    handleRecentDestinationItemSelected,
    handleCurrentLocation,
    handleDatesFieldClicked,
    handleDatesSelected,
    handleGuestsFieldClicked,
    handleGuestsNumberChanged,
    handleGuestsGroupTypeChanged,
    handleGuestsGroupAgesChanged,
    handleWizardClose,
    handleWizardOpen,
    handleSubmit,
    handleInventoryToggleClicked,
  };
}
