import { useCurrencyStore } from '@/stores/currency.js';

export function useApiAutoComplete() {
  const { $api } = useNuxtApp();
  const { locale } = useI18n();
  const currencyStore = useCurrencyStore();

  const userCurrency = currencyStore.getCurrentCurrency;

  const defaultQuery = {};

  const defaultHeaders = { 'Accept-Language': locale?.value };

  const defaultParams = { currency: userCurrency?.code };

  const abortController = ref(null);

  const abortSearch = () => {
    if (abortController.value) {
      abortController.value.abort();
      abortController.value = null;
    }
  };

  const searchForTerm = async (term) => {
    if (abortController.value !== null) {
      abortSearch();
    }

    abortController.value = new AbortController();

    const searchTermParams = {
      ...defaultParams,
      term,
    };

    const callKey = null;
    const endpoint = '/find/autocomplete/';

    const result = ref(await $api.acapi(callKey, endpoint, defaultHeaders, defaultQuery, searchTermParams));

    return result?.value || null;
  };

  return { searchForTerm };
}
