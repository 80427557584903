export default {
  minGuests: 9,
  minGuestsEnquire: 14,
  ageRanges: [
    {
      value: 1,
      label: '0-12',
    },
    {
      value: 2,
      label: '12-18',
    },
    {
      value: 3,
      label: '18-21',
    },
    {
      value: 4,
      label: '21-35',
    },
    {
      value: 5,
      label: '35-50',
    },
    {
      value: 6,
      label: '50+',
    },
  ],
  groupTypes: [
    {
      value: 1,
      label: 't_HOLIDAYWITHFRIENDS',
      bookingValue: 'Holiday with Friends',
      code: 'HOLIDAYWITHFRIENDS',
    },
    {
      value: 2,
      label: 't_JUNIORPRIMARYSCHOOL',
      bookingValue: 'Junior / Primary School',
      code: 'JUNIORPRIMARYSCHOOL',
    },
    {
      value: 3,
      label: 't_HIGHSECONDARYSCHOOL',
      bookingValue: 'High / Secondary School',
      code: 'HIGHSECONDARYSCHOOL',
    },
    {
      value: 4,
      label: 't_COLLEGEUNIVERSITY',
      bookingValue: 'College / University',
      code: 'COLLEGEUNIVERSITY',
    },
    {
      value: 5,
      label: 't_BUSINESSTRIP',
      bookingValue: 'Business Trip',
      code: 'BUSINESSTRIP',
    },
    {
      value: 6,
      label: 't_STAGHENBACHELORPARTY',
      bookingValue: 'Stag/Hen/Bachelor Party',
      code: 'STAGHENBACHELORPARTY',
    },
    {
      value: 7,
      label: 't_SPORTSGROUP',
      bookingValue: 'Sports Group',
      code: 'SPORTSGROUP',
    },
    {
      value: 8,
      label: 't_CULTURALGROUP',
      bookingValue: 'Cultural Group',
      code: 'CULTURALGROUP',
    },
  ],
};
