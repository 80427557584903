export const useLocationStore = defineStore('locationStore', () => {
  const location = ref({});

  const getLocationType = computed(() => {
    if (location.value?.property?.id) {
      return 'property';
    } else if (location.value?.district?.id) {
      return 'district';
    } else if (location.value?.city?.id) {
      return 'city';
    } else if (location.value?.region?.id) {
      return 'region';
    } else if (location.value?.area?.id) {
      return 'area';
    } else if (location.value?.country?.id) {
      return 'country';
    } else if (location.value?.continent?.id) {
      return 'continent';
    }

    return 'worldwide';
  });

  const getLocationInfo = computed(() => {
    return location.value[getLocationType.value] || {};
  });

  const getLocationFullInfo = computed(() => {
    return location.value;
  });

  const setLocation = (locationType, locationInfo) => {
    location.value[locationType] = {
      id: locationInfo?.id,
      name: locationInfo?.name,
      slug: locationInfo?.slug,
      hero: locationInfo?.image,
    };
  };

  return {
    location,
    getLocationType,
    getLocationInfo,
    getLocationFullInfo,
    setLocation,
  };
});
