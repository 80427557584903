<script setup>
import { WdsAvatar, WdsListItem, WdsSheet } from '@wds/components';

defineProps({
  hasLoggedInUser: {
    type: Boolean,
    required: false,
    default: false,
  },
  isOpen: {
    type: Boolean,
    required: false,
    default: false,
  },
  loggedInUser: {
    type: Object,
    required: false,
  },
  loggedInMenuItems: {
    type: Object,
    required: false,
  },
});

const emit = defineEmits(['closeMenu', 'handleLoggedInMenuItemClick']);
// ## COMPOSABLES
const { isBelowMediumScreen } = useMq();
</script>

<template>
  <div class="nav-button login-menu">
    <div
      v-if="hasLoggedInUser && !toValue(isBelowMediumScreen) && isOpen"
      class="button-popover login-popover"
    >
      <div class="login-menu-header">
        <WdsAvatar
          size="large"
          :user-name="loggedInUser?.name"
          :avatar-u-r-l="loggedInUser?.photo"
        />
      </div>
      <ul class="login-menu-body">
        <WdsListItem
          v-for="loggedInMenuItem in Object.keys(loggedInMenuItems)"
          :key="loggedInMenuItem"
          v-bind="loggedInMenuItems[loggedInMenuItem]"
          @click="emit('handleLoggedInMenuItemClick', loggedInMenuItem)"
        />
      </ul>
    </div>
    <WdsSheet
      v-if="hasLoggedInUser"
      :is-open="toValue(isBelowMediumScreen) && isOpen"
      :sheet-height="0"
      :head-divider="false"
      @sheet-close="emit('closeMenu')"
    >
      <template #sheetHeader>
        <div class="login-menu-header">
          <WdsAvatar
            size="large"
            :user-name="loggedInUser?.name"
            :avatar-u-r-l="loggedInUser?.photo"
          />
        </div>
      </template>
      <template #sheetBody>
        <ul class="login-menu-body">
          <WdsListItem
            v-for="loggedInMenuItem in Object.keys(loggedInMenuItems)"
            :key="loggedInMenuItem"
            v-bind="loggedInMenuItems[loggedInMenuItem]"
            @click="emit('handleLoggedInMenuItemClick', loggedInMenuItem)"
          />
        </ul>
      </template>
    </WdsSheet>
  </div>
</template>

<style lang="scss" scoped>
.nav-button {
  position: relative;
  display: inline-flex;

  .login-avatar {
    cursor: pointer;

    &:focus-visible {
      box-shadow:
        0 0 0 wds-rem(2px) $wds-color-white,
        0 0 0 wds-rem(4px) $wds-color-ink-darker;
      border-radius: 50%;
      outline: none;
    }

    :deep(.avatar-circle) {
      border: none;
    }
  }

  .button-popover {
    position: absolute;
    top: -12px;
    right: 0;
    margin-right: wds-rem(40px);
    background-color: $wds-color-white;
    z-index: $wds-z-index-dropdown;
    border-radius: $wds-border-radius-xl;
    box-shadow: $wds-shadow-light-l;
    min-width: wds-rem(300px);

    &.login-popover {
      padding: $wds-spacing-m;
      right: 0;
      margin-right: wds-rem(88px);
    }
  }

  &.login-menu {
    .login-menu-header {
      @include body-1-bld;

      @include desktop {
        margin-bottom: $wds-spacing-m;
      }
    }

    .login-menu-body {
      display: flex;
      flex-direction: column;
      margin-bottom: $wds-spacing-m;

      @include desktop {
        margin-bottom: 0;
      }
    }
  }
}
</style>
